import { axios, API_ROUTES } from "@/config/index.js";

export default {
  async getAll() {
    let result = await axios.get(`${API_ROUTES.planEnterprisePrice.get}`);

    return result;
  },

  async getAllByType(type) {
    let result = await axios.get(`${API_ROUTES.planEnterprisePriceByType.get}/${type}`);

    return result;
  },
}